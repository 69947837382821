import * as React from 'react';

import { Modal } from 'components/common-n4/pop-up-modal/pop-up-modal';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { track } from 'lib/tracking';
import type { HearingAid, Model, Release } from 'types/release';

import { Fallback } from './fallback';
import { Partner } from './partner';
import { Start } from './start';
import { Submitted } from './submitted';
import { Subscribe } from './subscribe';
import { Subscribed } from './subscribed';
import {
  type Action,
  type State,
  type SearchResponse,
  SearchResponseType,
  SubmitResponse,
  SubscribeResponse,
  Step,
  SystemAction,
  UserAction,
  ErrorCode,
  Event,
} from './types';

const log = logger({ category: 'ProviderFlow' });

export const CTASegments = {
  button: ['Find local savings on {release}', 'Save on {release}'],
  link: ['with local service'],
};

export const getCtaText = (text: string, segment: number, segmentOrigin: 'link' | 'button') => CTASegments[segmentOrigin][segment].replace(/{release}/, text);

const resetState = (state: State) => ({
  ...state,
  step: Step.start,
  error: undefined,
  searchResponse: undefined,
  submitResponse: undefined,
  subscribeResponse: undefined,
});

interface RecordABProps {
  event: Event;
  segment: number;
  segmentOrigin: 'link' | 'button';
  position: string;
}

export const recordEvent = ({ event, segment, segmentOrigin, position }: RecordABProps) => {
  // eslint-disable-next-line no-console
  console.debug('ab: %s/%d/%s', event, segment, segmentOrigin);
  if (segment >= 0 && segment < CTASegments[segmentOrigin].length) {
    track({
      event,
      eventType: 'abtest',
      origin: `provider-flow-${segmentOrigin}`,
      properties: { category: 'provider-flow', position, cta: CTASegments[segmentOrigin][segment] },
    });
  } else {
    track({ event, eventType: 'flow', origin: `provider-flow-${segmentOrigin}`, properties: { category: 'provider-flow', position } });
  }
};

const recordLocalEvent = (event: Event, state: State) => {
  recordEvent({ event, segment: state.segment, segmentOrigin: state.segmentOrigin, position: state.position });
};

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  log.silly('reducer: %o', { state, action });
  // allow payload to set the error, otherwise clear whenever state changes
  const newState = { ...state, error: undefined, ...payload };
  switch (type) {
    case SystemAction.error: {
      if (payload?.error) {
        recordLocalEvent(Event.Error, state);
      }
      // nothing to do, payload should contain the error
      break;
    }
    case UserAction.submit: {
      if (state.step === Step.start) {
        recordLocalEvent(Event.ZipCodeSubmitted, state);
        // validate zip in handler
        newState.step = Step.searching;
      } else if (state.step === Step.partner) {
        recordLocalEvent(Event.PhoneSubmitted, state);
        newState.step = Step.submitting;
      } else if (state.step === Step.subscribe) {
        recordLocalEvent(Event.Subscribe, state);
        // validate email in handler
        newState.step = Step.subscribing;
      }
      break;
    }
    case UserAction.restart: {
      // User said not their zip so start over
      recordLocalEvent(Event.Restart, state);
      return resetState(newState);
      break;
    }
    case SystemAction.search_response: {
      const response = payload!.searchResponse as SearchResponse;
      const { error, message } = response;
      if (error) {
        newState.error = { message: message!, field: undefined, code: ErrorCode.search_error_system };
        recordLocalEvent(Event.Error, newState);
        newState.step = Step.start;
      } else if (response.type === SearchResponseType.partner) {
        recordLocalEvent(Event.ZipHearingAvailable, state);
        newState.step = Step.partner;
      } else if (response.type === SearchResponseType.fallback) {
        recordLocalEvent(Event.Fallback, state);
        newState.step = Step.fallback;
      } else if (response.type === SearchResponseType.subscribe) {
        recordLocalEvent(Event.NoProviders, state);
        newState.step = Step.subscribe;
      }
      break;
    }
    case SystemAction.submit_response: {
      const response = payload!.submitResponse as SubmitResponse;
      const { error, message } = response;
      if (error) {
        if (/phone/i.test(message)) {
          newState.error = { message, field: 'phone', code: ErrorCode.submit_error_phone };
          recordLocalEvent(Event.Error, state);
        } else {
          newState.error = { message, field: undefined, code: ErrorCode.submit_error_system };
          recordLocalEvent(Event.Error, state);
        }

        newState.step = Step.partner;
      } else {
        newState.step = Step.submitted;
        recordLocalEvent(Event.LeadAccepted, state);
      }
      break;
    }
    case SystemAction.subscribe_response: {
      const response = payload!.subscribeResponse as SubscribeResponse;
      const { error, message } = response;
      if (error) {
        newState.error = { message, field: undefined, code: ErrorCode.subscribe_error_system };
        recordLocalEvent(Event.Error, state);
        newState.step = Step.subscribe;
      } else {
        recordLocalEvent(Event.SubscribeSuccess, state);
        newState.step = Step.subscribed;
      }
      break;
    }
  }
  log.silly('newState: %o', newState);
  return newState;
};

interface ProviderPopupProps {
  release: Pick<Release, 'slug' | 'name'>;
  model?: Pick<Model, 'id'>;
  hearingAid?: Pick<HearingAid, 'id'>;
  onClose: () => void;
  isModalOpen: boolean;
  origin: string;
  segment: number;
  segmentOrigin: 'link' | 'button';
  position: string;
}

export const ProviderFlow: React.FC<ProviderPopupProps> = ({
  release,
  model,
  hearingAid,
  onClose,
  isModalOpen = false,
  origin,
  segment,
  segmentOrigin,
  position,
}) => {
  const defaultState = { segment, segmentOrigin, step: Step.start, distance: 50, postalCode: '', phone: '', email: '', release, model, hearingAid, position };
  const [state, dispatch] = React.useReducer(reducer, defaultState as State);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    // Wait a sec so the user doesn't see the start step flash
    setTimeout(() => {
      dispatch({ type: UserAction.restart });
    }, 500);
    return true;
  };

  React.useEffect(() => {
    if (isModalOpen) {
      recordEvent({ event: Event.ButtonClicked, segment, segmentOrigin, position });
      dispatch({ type: SystemAction.set_segment, payload: { segment, position } });
    }
  }, [isModalOpen, segment, segmentOrigin, position]);

  React.useEffect(() => {
    (async () => {
      if (state.step === Step.searching) {
        // search then dispatch response type
        const searchResponse = await fetchApi({
          path: 'leads/find_providers',
          method: 'POST',
          variables: {
            postal_code: state.postalCode,
            distance: state.distance,
            hearing_aid_id: state.hearingAid?.id,
            model_id: state.model?.id,
            release_id: state.release?.slug,
            origin,
            href: window.location.href,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          fallback: 'friendly',
          log,
          origin: 'ProviderFlow#search',
        });
        log.debug('search: %o', searchResponse);
        dispatch({ type: SystemAction.search_response, payload: { searchResponse } });
      } else if (state.step === Step.submitting) {
        // subscribe then dispatch response type
        const submitResponse = await fetchApi({
          path: 'leads/submit_lead',
          method: 'POST',
          variables: {
            postal_code: state.postalCode,
            phone: state.phone,
            hearing_aid_id: state.hearingAid?.id,
            model_id: state.model?.id,
            release_id: state.release?.slug,
            origin,
            href: window.location.href,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            cta: getCtaText(release.name, state.segment, state.segmentOrigin),
          },
          fallback: 'friendly',
          log,
          origin: 'ProviderFlow#submit',
        });
        log.debug('submit: %o', submitResponse);
        dispatch({ type: SystemAction.submit_response, payload: { submitResponse } });
      } else if (state.step === Step.subscribing) {
        const subscribeResponse = await fetchApi({
          path: 'leads/subscribe',
          method: 'POST',
          variables: {
            postal_code: state.postalCode,
            email: state.email,
            hearing_aid_id: state.hearingAid?.id,
            model_id: state.model?.id,
            release_id: state.release?.slug,
            origin,
            href: window.location.href,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          fallback: 'friendly',
          log,
          origin: 'ProviderFlow#subscribe',
        });
        log.debug('subscribe: %o', subscribeResponse);
        dispatch({ type: SystemAction.subscribe_response, payload: { subscribeResponse } });
      }
    })();
  }, [state, origin, release?.name]);

  const { step } = state;

  let content;

  if (step === Step.start || step === Step.searching) {
    content = <Start dispatch={dispatch} state={state} />;
    // Partner flow, prompt for phone, submit lead, display provider
  } else if (step === Step.partner || step === Step.submitting) {
    content = <Partner dispatch={dispatch} state={state} />;
  } else if (step === Step.submitted) {
    content = <Submitted dispatch={dispatch} state={state} handleClose={handleClose} />;

    // Fallback flow, display provider, click to go to buy link
  } else if (step === Step.fallback) {
    content = <Fallback dispatch={dispatch} state={state} handleClose={handleClose} />;

    // Not found/subscribe flow, prompt for email, subscribe, display sub info
  } else if (step === Step.subscribe || step === Step.subscribing) {
    content = <Subscribe dispatch={dispatch} state={state} />;
  } else if (step === Step.subscribed) {
    content = <Subscribed dispatch={dispatch} state={state} handleClose={handleClose} />;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleClose}
      origin={`provider-flow/${release.slug}`}
      classes={{
        overlay: 'bg-navy/[0.80] lg:bg-navy/[0.80] md:bg-navy/[0.80] sm:bg-navy/[0.80] bg-navy/[0.80]',
        panel: 'md:rounded-[20px] rounded-t-[20px]',
      }}
    >
      <>{content}</>
    </Modal>
  );
};
