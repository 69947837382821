import snakeCase from 'lodash/snakeCase';
// import Plausible from 'plausible-tracker';

import { fetchApi } from './ht_api';

let initialized = false;

const clean = (key: string) => snakeCase(key.replace(/^event/, ''));

// const { trackEvent } = Plausible();

interface TrackProps {
  event: string;
  eventType: string;
  origin: string;
  properties?: Record<string, string | boolean | number | Date | undefined>;
}

const ahoy = async ({ event, eventType, origin, properties }: TrackProps) => {
  fetchApi({
    path: 'blimey/event',
    method: 'POST',
    variables: { name: event, properties: { ...properties, eventType } },
    fallback: null,
    origin,
  });
};

export const track = async ({ event, eventType, origin, properties }: TrackProps) => {
  if (process.env.VERCEL_GIT_COMMIT_REF !== 'main') {
    // eslint-disable-next-line no-console
    console.debug('track: %o', { event, eventType, origin, properties });
  }
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug('Skipping tracking in dev');
    return;
  }
  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (typeof window !== 'undefined') {
    const path = window.location.pathname;
    // trackEvent(event, { props: { ...properties, path } }); // Plausible
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', eventType, { ...properties, path }); // GA4
    }
    if (typeof window.posthog !== 'undefined' && window.posthog.capture) {
      window.posthog.capture(event, { ...properties, path });
    }
    ahoy({ event, eventType, properties: { ...properties, path }, origin }); // us
  }
};

export const setupTracking = () => {
  if (initialized) {
    return;
  }
  initialized = true;
  // Note: this only works on <a><span>... because we have pointer-events set to none
  // in styles/site.scss for a[data-track] elements.
  document.addEventListener('click', (e) => {
    if (e.target instanceof HTMLAnchorElement) {
      const element = e.target as HTMLAnchorElement;
      const { track: isTracking, eventName, origin, ...rest } = element.dataset;
      if (isTracking) {
        const path = window.location.pathname;
        const properties = Object.entries({ url: element.href, ...rest })
          .filter(([key]) => /event/.test(key))
          .reduce((h, [key, value]) => Object.assign(h, { [clean(key)]: value }), {});
        track({ event: `${eventName}--click` || element.id || 'click', eventType: 'click', origin: origin || path, properties });
      }
    }
  });
};

export const startTrackingObserver = () => {
  const intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const el = entry.target as HTMLAnchorElement;
      if (entry.isIntersecting && el.checkVisibility()) {
        if (!el.dataset.seen) {
          el.dataset.seen = 'true';
          const { eventName, origin, ...rest } = el.dataset;
          const path = window.location.pathname;
          const properties = Object.entries({ url: el.href, ...rest })
            .filter(([key]) => /event/.test(key))
            .reduce((h, [key, value]) => Object.assign(h, { [clean(key)]: value }), {});
          track({ event: `${eventName}--impression`, eventType: 'impression', origin: origin || path, properties });
        }
      }
    });
  });
  const tracked: HTMLElement[] = [];
  document.querySelectorAll('[data-track]').forEach((el) => {
    (el as HTMLElement).dataset.watching = 'true';
    tracked.push(el as HTMLElement);
    intersectionObserver.observe(el);
  });
  return { intersectionObserver, tracked };
};

export const stopTrackingObserver = (observer: { intersectionObserver: IntersectionObserver; tracked: HTMLElement[] }) => {
  const { intersectionObserver } = observer;
  intersectionObserver.disconnect();
};
