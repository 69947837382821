import React from 'react';

import { storyblokEditable, type SbBlokData } from '@storyblok/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import ReactMarkdown from 'react-markdown';

import { Avatar } from 'components/common-n4/avatar';
import { ShareButtons } from 'components/common-n4/share-buttons';
import { useApiData } from 'hooks/use_api_data';
import { cx } from 'lib/utils';

import markdownStyles from './markdown.module.scss';
import styles from './staff-card.module.scss';

interface Blok extends SbBlokData {
  staff_member: string;
}

interface StaffCardProps {
  blok: Blok;
}

const StaffCard: React.FC<StaffCardProps> = ({ blok }) => {
  const { staff_member: staffMember } = blok;
  const { authors } = useApiData();
  const router = useRouter();

  const author = authors[staffMember];
  if (!author) {
    return null;
  }

  // Determine the heading level based on the current path
  const HeadingTag = router.pathname.includes('/authors/') ? 'h1' : 'h4'; // Change to h3 or any other level if needed

  return (
    <section className={cx(styles.author, 'staff-card')} {...storyblokEditable(blok)}>
      <section className={styles['author-top']}>
        <section className={styles['author-content']}>
          <NextLink href={`/authors/${author.slug}`}>
            <section className={styles['author-intro']}>
              <Avatar.Root>
                <Avatar.Image src={author.avatar_url} />
                <Avatar.Fallback />
              </Avatar.Root>
              <section className={styles.intro}>
                <HeadingTag>{author?.name}</HeadingTag>
                <span>{author?.about_brief}</span>
              </section>
            </section>
          </NextLink>
        </section>
        <section className={styles['author-connect']}>
          {author?.social_links ? <ShareButtons.Primary links={author.social_links} title={author.title} /> : null}
        </section>
      </section>
      {author?.about_markdown && (
        <section className={cx(styles['author-description'], markdownStyles['markdown--author-bio'])}>
          <ReactMarkdown>{author.about_markdown}</ReactMarkdown>
        </section>
      )}
    </section>
  );
};

export default StaffCard;
