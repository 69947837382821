import React, { useState, useContext } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import Price from 'components/widgets/price';
import PriceButton from 'components/widgets/price-button';
import { tw } from 'lib/utils';
import type { LevelFeatureValue, ModelFeatureValue, Level } from 'types/release';

import { Feature } from './feature';
import { ModelComparisonContext } from './model-comparison';
import { getFeatureNameForFeature } from './utils';

const FEATURES_TO_SHOW_INITIALLY = 6;

interface FeaturePanelProps {
  title?: string;
  featureValues: ModelFeatureValue[] | LevelFeatureValue[];
  className?: ClassNameValue;
  type: string;
}

const FeaturePanel: React.FC<FeaturePanelProps> = ({ title, featureValues, className, type }) => {
  const { brandName, levelFeatureDetails, modelFeatureDetails } = useContext(ModelComparisonContext);

  const featureDetails = type === 'hardware' ? modelFeatureDetails : levelFeatureDetails;

  return (
    <div className={tw('lg:pb-12', className)}>
      <h4 className="text-[24px] font-medium leading-[120%] -tracking-[0.72px] text-navy lg:text-[20px] lg:text-xl lg:-tracking-[0.6px]">{title}</h4>
      <div className="mt-[24px] flex flex-wrap gap-y-[20px] lg:mt-8 lg:gap-y-8">
        {featureDetails?.map((featureDetail) => (
          <Feature
            key={featureDetail.id}
            type={type}
            name={getFeatureNameForFeature(featureDetail)}
            featureDetail={featureDetail}
            featureValues={featureValues}
            brandName={brandName}
            className="basis-full pb-[16px] pt-0 laptop:basis-1/2"
            titleClassName="text-[14px]"
            valueClassName="text-[18px]"
            iconInfoClassName="block mt-[1px]"
            iconInfoSmClassName="hidden"
          />
        ))}
      </div>
    </div>
  );
};

interface SingleProductTabPanelProps {
  releaseSlug: string;
  level: Level;
}

export const SingleProductTabPanel: React.FC<SingleProductTabPanelProps> = ({ releaseSlug, level }) => {
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const { release, models } = useContext(ModelComparisonContext);
  const model = models?.[0];
  const hearingAid = (release?.hearing_aids || []).find((ha) => ha.modelId === model.id && ha.levelId === level.id);
  const totalScore = hearingAid?.scores?.total_score || 0;

  return (
    <div className="lg:px-12">
      <section className="flex flex-col lg:flex-row lg:gap-8">
        <div className="flex flex-1 flex-col gap-8">
          <div
            className="max-w-[497px] rounded-[20px] border border-navy-20 p-[24px] md:border-navy/5 md:shadow-sm 
          lg:max-w-[400px] lg:p-8 laptop:max-w-[497px]"
          >
            <div className="relative">
              {model?.image && (
                <div className="flex justify-center">
                  <div className="max-w-[200px] lg:max-w-[230px]">
                    <ImageWrapper image={model.image} imgProps={{ className: 'p-4 object-contain' }} origin="model-comparison-single-product-tab" />
                  </div>
                </div>
              )}
              {(totalScore || 0) > 0 && (
                <div className="absolute right-0 top-0">
                  <CircleScoreFluid
                    size="md"
                    progress={(totalScore / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
                    amount={totalScore}
                    className="shadow-sm"
                    origin="product/model-comparison/single-product-tab-panel"
                  />
                </div>
              )}
            </div>
            <div className="mt-6">
              <h3 className="text-[24px] font-medium leading-[120%] -tracking-[0.72px] text-navy lg:text-2xl lg:text-[20px] lg:-tracking-[0.6px]">
                {model?.full_name}
              </h3>
              <p className="flex min-h-[22px] items-start gap-2 text-[12px] -tracking-[0.36px] text-navy/60 lg:text-lg lg:leading-[120%] lg:-tracking-[0.54px]">
                <Price
                  loading="Loading price..."
                  suffix=" a pair"
                  releaseSlug={releaseSlug}
                  levelId={level.id}
                  modelId={model.id}
                  hearingAidId={hearingAid?.id}
                  price={hearingAid?.price || level?.price || model?.price || release?.price}
                  noGeo
                  origin="product/model-comparison/single"
                />
              </p>
            </div>
            <PriceButton
              release={release!}
              model={model}
              hearingAid={hearingAid}
              price={hearingAid?.price || model.price || release?.price}
              noGeo
              className="mt-4 h-[40px] !w-full justify-center p-2 text-base lg:mt-6 lg:h-[48px] lg:p-0"
              origin="product/model-comparison-single"
              position="product-model-comparison"
            />
          </div>
        </div>
        <section className="flex flex-1 flex-col pt-[48px] lg:pt-0">
          <FeaturePanel title="Hardware" featureValues={model?.features} className="pb-[19px] lg:pt-6" type="hardware" />
          {isViewMore && <FeaturePanel className="border-t border-navy/40 pt-10" title="Software" featureValues={level.features} type="software" />}
        </section>
      </section>
      <ViewMoreButton
        isViewMore={isViewMore}
        onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
        className="mt-[40px] lg:mt-16"
        negativeStateText="Show all features"
        positiveStateText="Show fewer features"
        hidden={model?.features.length <= FEATURES_TO_SHOW_INITIALLY && level.features.length <= FEATURES_TO_SHOW_INITIALLY}
      />
    </div>
  );
};
